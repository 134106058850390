import '../MailsDiv/MailsDiv-component.css'
import '../../App.css'
import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const SmsDivComponent = ({name}: { name: string }) => {

    const dataFetchedRef = useRef(false);

    const navigate = useNavigate();

    const [increment, setIncrement] = useState(5)

    const [mails, setMails] = useState<any[]>([])

    const [mailsTrierJour, setMailTrierJour] = useState<any[]>([])

    const [dernierIncident, setLastIssue] = useState<string>("")

    const [nbrIncident, setNbrIncident] = useState<number>(0)

    function getCurrentDate(separator = '/', number: number) {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = String(newDate.getFullYear()).slice(2);

        if (number === 1) {
            return `${date < 10 ? `0${date}` : `${date}`}/${month < 10 ? `0${month}` : `${month}`}/${year} 14:00`
        }
        if (number === 2) {
            return `${date < 10 ? `0${date}` : `${date}`}/${month < 10 ? `0${month}` : `${month}`}/${year} 8:00`
        }

    }

    const handleOnClick = () => {
        setIncrement(increment + 5)
    }

    let comptage = 0

    //Fonction ansyncrone pour récupèrer les mails
    async function getMails() {

        await axios.get(
            `https://dashboard.api.digital-jpgc.com/index.php/get/api/sms/${name}`)
            .then((lesMails) => {
                const lesMail = lesMails.data
                setMails(lesMail)

                lesMail.forEach((mail:any) => {
                    if (mail.length === 2 && mail[0].envoyer === mail[1].envoyer) {
                        comptage++
                    }
                })

                setNbrIncident(comptage)
                })

    }


    //Quand l'increment change, on relance le code donc mails change et vus que c'est un state le composant se reload :)
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true
        getMails()
    }, [increment])

    return (
        <div className={"mails"}>
            <p className={"lastIssue"}>{dernierIncident !== '' ? `Dernier incident le : ${dernierIncident}` : 'Aucun incident'}</p>
            <p style={{textAlign: 'center'}}>Nombre d'incidents total : {nbrIncident} </p>
            <h2 className={'titreMails'}>{name}</h2>
            {mails.slice(0, increment).map(mailJournalier =>
                <div onClick={() => {
                    navigate('/sms-detail', {state: {mail: mailJournalier}})
                }
                } style={mailJournalier.length == 2 ? ((mailJournalier[0].envoyer === mailJournalier[1].envoyer) ? (mailJournalier[0].envoyer === null || mailJournalier[1].envoyer === null) ? {backgroundColor: 'lightgrey'} : {
                    backgroundColor: 'lightcoral',
                    fontSize: '18px',
                    fontFamily: 'fantasy'
                } : {backgroundColor: 'white'}) : {backgroundColor: 'lightblue'}} key={mailJournalier[0].id}>

                    {mailJournalier.map((mail: { id: number, campagne: string, imageURL: string, date: string, envoyer: boolean, erreur: string, nbContact: number },index:number) =>
                        <div className={'recapMailContainer'} key={index}>
                            <div>{mail.date}</div>
                            <div><img src={mail.imageURL} alt={'Valider ou non'}/></div>
                        </div>
                    )}
                </div>
            )}
            <div id={'readMoreBtn'}>
                <button id={'readMore'} onClick={handleOnClick}>Voir plus &rsaquo;&rsaquo;</button>
            </div>
        </div>

    )
}

export default SmsDivComponent
