import React from 'react';
import './App.css';
import {MenuComponent} from './menu/Menu-component';
import {MailsComponent} from './Mails/Mails-component'
import {Route, Routes, BrowserRouter, NavLink} from 'react-router-dom';
import {ScriptsComponent} from './Scripts/Scripts-component';
import {MailDetailsComponent} from "./Mails/MailsDetails/MailDetails-component";
import {SmsDetailsComponent} from './Mails/SmsDiv/SmsDetails/SmsDetails-component';
import {RapportSms} from './RapportSMS/RapportSms';

function App() {
  return (
      <BrowserRouter>
        <div className="App">
          <MenuComponent />
            <Routes>
                <Route path="/" element={<MailsComponent />} />
                <Route path="/scripts" element={<ScriptsComponent />} />
                <Route path={"/mail-detail"} element={<MailDetailsComponent/>} />
                <Route path={"/rapport-sms"} element={<RapportSms/>} />
                <Route path={"/sms-detail"} element={<SmsDetailsComponent/>} />
            </Routes>
        </div>
      </BrowserRouter>

  );
}

export default App;
