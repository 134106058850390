import {useState} from 'react';
import axios from 'axios';
import "./Rapport-Sms.css"

export const RapportSms = () => {

    const [dateDeb, setDateDeb] = useState('');
    const [dateFin, setDateFin] = useState('');
    const [loading, setLoading] = useState(false);

    const formatDateTime = (datetimeLocal:string) => {
        return datetimeLocal.replace('T', ' ');
    };

    const downloadFile = async () => {
        setLoading(true);

        try {
            const params = new URLSearchParams();
            params.append('date_deb', formatDateTime(dateDeb));
            params.append('date_fin', formatDateTime(dateFin));

            const response = await axios.post('https://dashboard.api.digital-jpgc.com/index.php/api/rapportsms', params, {
                responseType: 'blob',
            });

            console.log(response)

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'rapport.zip');

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            // @ts-ignore
            console.error('Erreur lors du téléchargement du fichier:', error.response);
            alert('Une erreur est survenue lors du téléchargement du fichier.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div id={"rapportDiv"}>
            <div id="dateInput">
                <div>
                    <label className={"date-picker"}>
                        Date Début (YYYY-MM-DD hh:mm):
                        <input
                            type="datetime-local"
                            value={dateDeb}
                            onChange={(e) => setDateDeb(e.target.value)}
                        />
                    </label>
                </div>
                <div>
                    <label className={"date-picker"}>
                        Date Fin (YYYY-MM-DD hh:mm):
                        <input
                            type="datetime-local"
                            value={dateFin}
                            onChange={(e) => setDateFin(e.target.value)}
                        />
                    </label>
                </div>
            </div>
            <button onClick={downloadFile} disabled={loading}>
                {loading ? "Téléchargement en cours..." : "Télécharger le fichier"}
            </button>
        </div>
    );
};

