import './MailDetails-component.css'
import {useLocation, useNavigate} from "react-router-dom";

export const MailDetailsComponent = () =>{

    const navigate = useNavigate()

    let state = useLocation().state.mail

    let mails = state

    if (state.length ==2){
        mails = [state[1],state[0]]
    }
    else{
        mails = [state[0]]
    }


    return(
        <div>
            <div id={"mailDetailsContainer"}>
                <h1>Mail du <span style={{color:'#D28648'}}>{mails[0].date.slice(0,9)}</span> campagne <span style={{color:'#D28648'}}>{mails[0].campagne}</span></h1>
                <div id={"mailsDetails"}>
                    {mails.map((mail:{ id: number, campagne: string, imageURL: string, date: string, envoyer: boolean, erreur: string, nbContact: number}) =>
                        <div key={mail.id} className={'mailDetail'} style={mail.envoyer ? {borderColor:'green'} : {borderColor:'red'}}>
                            <p>Heure d'envoi : {mail.date.slice(9,11)}h{mail.date.slice(12)}</p>
                            <p>Envoyer : {mail.envoyer ? 'Oui':'Non'}</p>
                            <p>Message : {mail.erreur}</p>
                        </div>
                    )}
                </div>
                <button onClick={()=>navigate('/')} id={'returnToDashboard'}>Retour</button>
            </div>
        </div>
    )
}