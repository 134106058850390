import './Menu-component.css'
import {NavLink} from 'react-router-dom';

export function MenuComponent(){
    return(
        <nav id={"navbar"}>
            <li><NavLink to={"/"}>Dashboard</NavLink></li>
            <li><NavLink to={"/scripts"}>Scripts</NavLink></li>
            <li><NavLink to={"/rapport-sms"}>Rapport SMS</NavLink></li>
        </nav>
    )
}
