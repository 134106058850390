import './Scripts-component.css'

export function ScriptsComponent(){


    const handleAction = (name:string) =>{

        if(name!=='Clean Listes'){
            if (window.confirm(`Etes-vous sur de vouloir lancer la campagne ${name} ? Une fois lancer cela ne pourra pas être annulé`)){
                window.open(`https://agence-jpgc.com/testCofidis/index_${name}.php`)
            }
        }
        else{
            if (window.confirm(`Etes-vous sur de vouloir lancer le ${name} ? Une fois lancer ca ne pourra plus être modifié`)){
                window.open('https://agence-jpgc.com/testCofidis/cleanlist.php')
            }
        }


    }


    return(
        <div id={"mainScript"}>
            <div id={"scriptDesc"}>
                <h1>Liste des scripts (Utiliser avec précaution)</h1>
                <div id={'scriptContainer'}>
                    <div>
                        <button onClick={()=>handleAction('12676')} className={'actionBtn'}>Relance 12676</button>
                    </div>
                    <div>
                        <button onClick={()=>handleAction('14077')} className={'actionBtn'}>Relance 14077</button>
                    </div>
                    <div>
                        <button onClick={()=>handleAction('14104')} className={'actionBtn'}>Relance 14104</button>
                    </div>
                    <div>
                        <button onClick={()=>handleAction('Clean Listes')} className={'actionBtn'}>Clean les listes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
