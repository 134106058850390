import './MailsDiv-component.css'
import '../../App.css'
import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const  MaislDivComponent = ({name}: { name: string }) => {

    const dataFetchedRef = useRef(false);

    const navigate = useNavigate();

    const [increment, setIncrement] = useState(5)

    const [mails, setMails] = useState<any[]>([])

    const [dernierIncident, setLastIssue] = useState<string>("")

    const [nbrIncident, setNbrIncident] = useState<number>(0)

    const handleOnClick = () => {
        setIncrement(increment + 5)
    }

    let comptage = 0

    //Fonction ansyncrone pour récupèrer les mails
    async function getMails() {

        await axios.get(
            `https://dashboard.api.digital-jpgc.com/index.php/get/api/mail/${name}`)
            .then((lesMails) => {
                const lesMail = lesMails.data
                setMails(lesMail)

                lesMail.forEach((mail:any) => {
                    if (mail.length === 2 && mail[0].envoyer === mail[1].envoyer) {
                        comptage++
                    }
                })
                setNbrIncident(comptage)

             })
    }


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true
        getMails()
    }, [increment])

    return (
        <div className={"mails"}>
            <p className={"lastIssue"}>{dernierIncident !== '' ? `Dernier incident le : ${dernierIncident}` : 'Aucun incident'}</p>
            <p style={{textAlign: 'center'}}>Nombre d'incidents total : {nbrIncident} </p>
            <h2 className={'titreMails'}>{name}</h2>
            {mails.slice(0, increment).map(mailJournalier =>
                <div onClick={() => {
                    navigate('/mail-detail', {state: {mail: mailJournalier}})
                }
                } style={mailJournalier.length == 2 ? ((mailJournalier[0].envoyer === mailJournalier[1].envoyer) ? (mailJournalier[0].envoyer === null || mailJournalier[1].envoyer === null) ? {backgroundColor: 'lightgrey'} : {
                    backgroundColor: 'lightcoral',
                    fontSize: '18px',
                    fontFamily: 'fantasy'
                } : {backgroundColor: 'white'}) : {backgroundColor: 'lightblue'}} key={mailJournalier[0].id}>

                    {mailJournalier.map((mail: { id: number, campagne: string, imageURL: string, date: string, envoyer: boolean, erreur: string, nbContact: number },index:number) =>
                        <div className={'recapMailContainer'} key={index}>
                            <div>{mail.date}</div>
                            <div><img src={mail.imageURL} alt={'Valider ou non'}/></div>
                        </div>
                    )}
                </div>
            )}
            <div id={'readMoreBtn'}>
                <button id={'readMore'} onClick={handleOnClick}>Voir plus &rsaquo;&rsaquo;</button>
            </div>
        </div>

    )
}

export default MaislDivComponent
