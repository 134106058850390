import MaislDivComponent from './MailsDiv/MaislDiv-component';
import './Mails-component.css'
import SmsDivComponent from './SmsDiv/Sms-Div-Component';

export function MailsComponent() {
    return (
        <div id={'mailsContainer'}>
            <MaislDivComponent
            name={"12676"}
             />
            <SmsDivComponent name={"12678"}/>
            <SmsDivComponent name={"13122"}/>

        </div>
    )
}
